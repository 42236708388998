.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@font-face {
    font-family: 'Circular Pro Black';
    src: url('fonts/CircularPro-Black.otf') format('opentype');
}

@font-face {
    font-family: 'Circular Pro Book';
    src: url('fonts/CircularPro-Book.otf') format('opentype');
}
@font-face {
    font-family: 'Circular Pro Bold';
    font-weight: bold;
    src:  url('fonts/CircularPro-Bold.otf') format('opentype');
}
@font-face {
    font-family: 'Circular Pro Medium';
    font-weight: bold;
    src:  url('fonts/CircularPro-Medium.otf') format('opentype');
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}



.headerBackground {
    background: linear-gradient(89.78deg, #32AE34 0%, #FAC705 100%);
}

.boldText{
    width:62%;
    font-family: 'Circular Pro Book';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    /* or 23px */

    letter-spacing: 0.006em;

    color: #282c34;
}
.textBoldMobile{

    font-family: 'Circular Pro Book';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 130%;
    /* or 23px */

    letter-spacing: 0.006em;

    color: #282c34;
}

.pwText{
    width:62%;
}

.logo {
    width: 82px;
    margin-left: 22px;
    margin-top: 19px;
    font-size: 40px;
    font-weight: bold;
    color: #FFFFFF;
}
.logoLink{
    text-decoration: none;
    color: inherit;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    flex-wrap: wrap;
    width: 100%;
}

.exposureHeader {
    width: 100%;
    left: 0px;
    font-family: 'Circular Pro Book';
    font-style: normal;
    font-weight: bold;
    font-size: 33px;
    text-align: center;
    letter-spacing: 0.006em;
    color: #FFFFFF;

}
.exposureHeaderMobile{
    width: 95%;
    left: 0px;
    font-family: 'Circular Pro Book';
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    text-align: center;
    letter-spacing: 0.006em;
    color: #FFFFFF;
}

.spinning{
    animation: spin 1.5s infinite linear;
}

.exposureMessage {
    width: 100%;
    left: 0px;
    margin-top: 1%;

    font-family: 'Circular Pro Book';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    /* or 23px */
    justify-content: center;
    text-align: center;
    letter-spacing: 0.006em;

    color: #FFFFFF;



}
.exposureMessageMobile {
    width: 95%;
    left: 0px;
    margin-top: 1%;

    font-family: 'Circular Pro Book';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    /* or 23px */
    justify-content: center;
    text-align: center;
    letter-spacing: 0.006em;

    color: #FFFFFF;

}
.formatMessage{
    width: 100%;
    left: 0px;
    margin-bottom: 0.2%;
    font-family: 'Circular Pro Book';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    /* or 23px */
    justify-content: center;
    text-align: center;
    letter-spacing: 0.006em;

    color: #FFFFFF;
}
.formatMessageMobile{
    width: 95%;
    left: 0px;
    margin-bottom: 0.2%;
    font-family: 'Circular Pro Book';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    /* or 23px */
    justify-content: center;
    text-align: center;
    letter-spacing: 0.006em;

    color: #FFFFFF;
}
.exposureMessageResult {
    width: 100%;
    left: 0px;
    margin-top: 1%;

    font-family: 'Circular Pro Book';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    /* or 23px */

    text-align: center;
    letter-spacing: 0.006em;

    color: #FFFFFF;

}

.searchContainer{
    min-height: 95vh;
}

.formContainer{
    justify-content: center;
    margin-top:1%;
    margin-bottom: 15px;
}
.input-container{
    border: 1px solid #a9a9a9;
    display: inline-flex;
    /*width: 671px;*/
    height: 47px;
    background: #FFFFFF;
    border-radius: 2px;
    left: 0px;
    top: 136px;
}
.input-container input:focus, .input-container input:active {
    outline: none;
}

.input-container button {
    float: right;
    width: 138px;
    height: 47px;
    left: 533px;
    background: #4B5B4E;
    border-radius: 0px 2px 2px 0px;
    color: #FFFFFF;
    box-shadow: none;
}


@media screen and  (min-width: 1100px) {
    .input-container {
        border: 1px solid #a9a9a9;
        display: inline-flex;
        width: 671px;
        height: 47px;
        background: #FFFFFF;
        border-radius: 2px;
        left: 0px;
        top: 136px;

    }

    .input-container input {
        width: 80%;
        border: none;
        left: 10px;
        top: 12px;
    }

}

/*Mobile screens size >= 300px*/
@media screen and  (min-width: 1100px) {


    .input-container {
        border: 1px solid #a9a9a9;
        display: inline-flex;
        height: 47px;
        width: 671px;
        background: #FFFFFF;
        border-radius: 2px;
        height: 47px;
        left: 0px;
        top: 136px;

    }

    .input-container input {
        width: 90%;
        border: none;
        margin-left: 10px;
        top: 12px;
    }
}

@media screen and (max-width: 800px) {

    .input-container {
        border: 1px solid #a9a9a9;
        display: inline-flex;
        height: 47px;
        background: #FFFFFF;
        border-radius: 2px;
        height: 47px;
        left: 0px;
        top: 136px;

    }

    .input-container input:focus, .input-container input:active {
        outline: none;
    }

    .input-container input {
        width: 100%;
        border: none;
        left: 10px;
        top: 12px;
    }

    .input-container button {
        float: right;
        width: 138px;
        height: 47px;
        left: 533px;
        background: #4B5B4E;
        border-radius: 0px 2px 2px 0px;
        color: #FFFFFF;
        box-shadow: none;
    }
}

::placeholder {
    position: absolute;
    width: 460px;
    height: 23px;
    left: 10px;
    top: 12px;

    font-family: 'Circular Pro Book';
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 130%;

    letter-spacing: 0.006em;

    color: #363636;
}


.mockImg {
    width: 425px;
    height: 300px;
    margin-left: auto;
}

.mockupImage {
    align-items: center;
    width: 50%;
    text-align: right;
}
.mockupImageMobile{
    justify-content: center;
    width: 100% !important;
    text-align: center;
    align-items: center;
    /*width: 50%;*/
    /*text-align: right;*/
}
.mockImgMobile{
    width: 350px;
    height: 267px;
}
.mockupText {
    width: 50%;
    left: 0px;
    margin-top: 20px;
    font-family: 'Circular Pro Book', 'sans-serif';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: 0.006em;
    color: #363636;
}
.mockupTextMobile {
    width: 100% !important;
    left: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Circular Pro Book', 'sans-serif';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    letter-spacing: 0.006em;
    color: #363636;
    justify-content: center;
    text-align: center;
}

.myButton {
    background-color: #FFA800;
    border-radius: 2px;
    border: 1px solid #FFA800;
    cursor: pointer;
    color: #ffffff;
    text-decoration: none;

    font-family: 'Circular Pro Book','sans-serif';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.006em;
    width: 141px;
    height: 31px;
    padding: 10px 17px 10px 17px;
}



.footer {

    padding: 15px 0px 15px 0px;

    justify-content: center;
    background: #F8F9FB;
}

.footerLink {

    font-family: 'Circular Pro Book';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;

    text-align: center;
    letter-spacing: 0.006em;
    text-decoration: none;
    color: #31AD34;
}

@keyframes spin {
    0%  {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}
.exposureMessageBlack {
    width: 100%;
    left: 0px;
    margin-top: 1%;

    font-family: 'Circular Pro Book';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    /* or 23px */

    text-align: center;
    justify-content: center;
    letter-spacing: 0.006em;

    color: #282c34;

}
.exposureMessageBlackMobile {
    width: 100%;
    left: 0px;
    margin-top: 1%;

    font-family: 'Circular Pro Book';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    /* or 23px */

    text-align: center;
    justify-content: center;
    letter-spacing: 0.006em;

    color: #282c34;

}

